$color-base-white: #fff;
$color-base-50: #f0f0e7;
$color-base-100: #f3f4f6;
$color-base-200: #e5e7eb;
$color-base-300: #d1d5db;
$color-base-400: #9ca3af;
$color-base-500: #636363;
$color-base-600: #4b5563;
$color-base-700: #292929;
$color-base-800: #1f2937;
$color-base-900: #000;
$color-error-shade: #bc1010;
$color-error: #ef4444;
$color-error-tint: #f58a8a;
$color-warning-shade: #856605;
$color-warning: #eab308;
$color-warning-tint: #f9cd44;
$color-warning-bg: #fef3c7;
$color-text: $color-base-700;
$color-headings: $color-base-700;
$color-borders: $color-base-200;
