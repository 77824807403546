    @import "styles/variables";
    @import "styles/mixins";

@import "container";
@import "typography";

@font-face {
    font-family: iconfont;
    src: url("https://cdn.roompot.com/static/fonts/iconfont.woff2");
}
